import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid2'
import ContainerText from '../../components/container_text'
import SEO from '../../components/seo'

const Title = styled.h3`
  padding-bottom: 2rem;
`

const IndexPage = () => (
  <div>
    <SEO title="Shipping & Returns" keywords={[`Privacy Policy`, `Returns`,`Theodora`, `Warre`]} />
    <ContainerText longpage>
      <Container>
        <Row>
          <Col xlOffset="2" xl="8" lgOffest="2" lg="8" md="12" sm="12">
            <Title>Privacy Policy</Title>
            <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from https://www.theodorawarre.co.uk (the “Site”).</p>
            <p>PERSONAL INFORMATION WE COLLECT<br />
            When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”</p>
            <p>We collect Device Information using the following technologies:</p>
            <p>- “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.</p>
            <p>- “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</p>
            <p>- “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.</p>
            <p>Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers [[INSERT ANY OTHER PAYMENT TYPES ACCEPTED]]), email address, and phone number.  We refer to this information as “Order Information.”</p>
            <p>When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.</p>
            <p>HOW DO WE USE YOUR PERSONAL INFORMATION?<br />
            We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).</p>
            <p>Additionally, we use this Order Information to:</p>
            <p>Communicate with you;<br />
            Screen our orders for potential risk or fraud; and<br />
            When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</p>
            <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</p>
            <p>SHARING YOUR PERSONAL INFORMATION<br />
            We share your Personal Information with third parties to help us use your Personal Information, as described above.  For example, we use Shopify to power our online store--you can read more about how Shopify uses your Personal Information here:  https://www.shopify.com/legal/privacy.  We also use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here:  https://www.google.com/intl/en/policies/privacy/.  You can also opt-out of Google Analytics here:  https://tools.google.com/dlpage/gaoptout.</p>
            <p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
            <p>As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.  For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.</p>
            <p>Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at:  http://optout.aboutads.info/.</p>
            <p>DO NOT TRACK<br />
            Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>
            <p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</p>
            <p>Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above.  Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</p>
            <p>DATA RETENTION<br />
            When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>
            <p>CHANGES<br />
            We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
            <p>CONTACT US<br />
            For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at theodora@theodorawarre.co.uk.</p>
            <Title>Cookie Policy for Theodora Warre.</Title>
            <p>This is the Cookie Policy for Theodora Warre., accessible from http://www.theodorawarre.co.uk/</p>
            <p>What Are Cookies</p>
            <p>As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.</p>
            <p>For more general information on cookies see the <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/HTTP_cookie">Wikipedia article on HTTP Cookies.</a></p>
            <p>How We Use Cookies</p>
            <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</p>
            <p>Disabling Cookies</p>
            <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.</p>
            <p>The Cookies We Set</p>
            <p>Account related cookies If you create an account with us then we will use cookies for the management of the signup process and general administration. These cookies will usually be deleted when you log out however in some cases they may remain afterwards to remember your site preferences when logged out.</p>
            <p>Login related cookies We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.</p>
            <p>Email newsletters related cookies This site offers newsletter or email subscription services and cookies may be used to remember if you are already registered and whether to show certain notifications which might only be valid to subscribed/unsubscribed users.</p>
            <p>Forms related cookies When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.</p>
            <p>Third Party Cookies</p>
            <p>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</p>
            <p>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content. For more information on Google Analytics cookies, see the official Google Analytics page.</p>
            <p>More Information</p>
            <p>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site. This Cookies Policy was created with the help of the Generator of GDPR Cookies Policy Template and the GDPR Cookies Policy Template.</p>
            <p>However if you are still looking for more information then you can contact us through one of our preferred contact methods:</p>
            <p>By visiting this link: https://www.theodorawarre.co.uk/contact</p>
          </Col>
        </Row>
      </Container>
    </ContainerText>
  </div>
)

export default IndexPage
